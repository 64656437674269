import clsx from 'clsx';
import { isFunction, lowerCase, noop } from 'lodash-es';
import React from 'react';
import { Dialog, DialogProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { IconButton } from '../../molecules/icon-button/icon-button';
import { Heading } from '../heading/heading';
import { Separator } from '../separator/separator';

const DialogContext = React.createContext({ close: noop });

function useDialogContext() {
  return React.useContext(DialogContext);
}

function DialogHeader({
  title,
  showCloseButton = true,
  children,
  mobile,
}: {
  title?: string;
  showCloseButton?: boolean;
  children?: React.ReactNode;
  mobile?: boolean; // In resident portal the media query is desktop but the view is mobile
}) {
  const { close } = useDialogContext();

  return (
    <div
      className={clsx(
        'flex items-center gap-x-3 px-4 pt-4',
        !mobile && 'md:px-6 md:pt-6',
        title ? 'justify-between' : 'justify-end',
      )}>
      {title && (
        <Heading className={clsx('text-grey-900 text-h3', !mobile && 'md:text-h2')} slot="title">
          {title}
        </Heading>
      )}
      {showCloseButton && (
        <>
          <IconButton
            aria-label={title ? `Close ${lowerCase(title)} dialog` : 'Close dialog'}
            className="hidden md:block"
            icon="close"
            onPress={close}
          />
          <IconButton
            aria-label={title ? `Close ${lowerCase(title)} dialog` : 'Close dialog'}
            className="md:hidden"
            icon={'chevron-right'}
            onPress={close}
          />
        </>
      )}
      {children}
    </div>
  );
}

function DialogBody({
  children,
  className,
  mobile,
}: {
  children: React.ReactNode;
  className?: string;
  mobile?: boolean;
}) {
  return (
    <div className={twMerge('space-y-8 px-4 py-4', clsx(!mobile && 'md:px-6 md:py-6'), className)}>
      {children}
    </div>
  );
}

type DialogFooterChildrenFn = ({ close }: { close: () => void }) => React.ReactNode;

function DialogFooter({
  children,
  className,
  mobile,
}: {
  children: React.ReactNode | DialogFooterChildrenFn;
  className?: string;
  mobile?: boolean;
}) {
  const { close } = useDialogContext();

  return (
    <>
      <div
        className={twMerge(
          'flex flex-wrap items-center justify-end gap-3 px-4 pb-4',
          clsx(!mobile && 'md:px-6 md:pb-6'),
          className,
        )}>
        {isFunction(children) ? children({ close }) : children}
      </div>
    </>
  );
}

export function DialogSeparator() {
  return (
    <div className="px-4 md:px-6">
      <Separator />
    </div>
  );
}

const _Dialog = Object.assign(
  React.forwardRef<HTMLElement, DialogProps>(({ children, className, ...props }, ref) => {
    return (
      <Dialog
        className={twMerge(
          'flex h-full flex-1 flex-col overflow-y-auto focus:outline-none',
          className,
        )}
        ref={ref}
        {...props}>
        {({ close }) => (
          <DialogContext.Provider value={{ close }}>
            {isFunction(children) ? children({ close }) : children}
          </DialogContext.Provider>
        )}
      </Dialog>
    );
  }),
  {
    Header: DialogHeader,
    Body: DialogBody,
    useContext: useDialogContext,
    Footer: DialogFooter,
    Separator: DialogSeparator,
  },
);

export { _Dialog as Dialog };
